import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// 1. 引入你需要的组件
// import { Button, Tabbar, TabbarItem, Cell, CellGroup, Icon, Popup,} from 'vant';
import { Button, Tabbar, TabbarItem,Icon,Cell,CellGroup, Form, Field,Popup,Picker,DatetimePicker,Checkbox,Dialog } from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';

// 3. 注册你需要的组件
Vue.use(Button);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Icon);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Form);
Vue.use(Field);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(DatetimePicker);
Vue.use(Checkbox);
Vue.use(Dialog);
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
