<template>
  <div class="certification">
    <div class="back" @click="back"><van-icon name="arrow-left" />返回</div>
    <div class="tip-top"> <van-icon class="tip-top-icon" name="warning-o" /><div>为了保护您的物品安全，平台需核实您办理业务时预留的身份信息和收件手机号，请如实填写，感谢您的支持与配合</div></div>
    <div class="certification-form">
        <div class="certification-form-title">申请人信息</div>
        <div class="img-flex" @click="tip"><img class="img" src="../assets/05.png" alt="" ><img class="img" src="../assets/04.png" alt="" ></div>
        <van-form @submit="onSubmit">
            <van-cell-group inset  :border="false">
                <van-field
                v-model="userName"
                name="姓名"
                label="姓名"
                placeholder="请输入姓名"
                :border="false"
                :rules="[{ required: true, message: '请填写姓名' }]"
                />
                <van-field
                v-model="sex"
                name="性别"
                label="请选择性别"
                is-link
                placeholder="性别"
                :border="false"
                />
                <van-field
                v-model="ethnic"
                name="民族"
                label="民族"
                placeholder="请输入民族"
                :border="false"
                :rules="[{ required: true, message: '请填写民族' }]"
                />
                <van-field
                v-model="birthday"
                name="出生日期"
                label="出生日期"
                is-link
                placeholder="请选择出生日期"
                :border="false"
                />
                <van-field
                v-model="address"
                name="住址"
                label="住址"
                placeholder="请输入住址"
                :border="false"
                :rules="[{ required: true, message: '请填写住址' }]"
                />
                <van-field
                v-model="ID"
                name="身份证号码"
                label="身份证号码"
                placeholder="请输入身份证号码"
                :border="false"
                :rules="[{ required: true, message: '请填写身份证号码' }]"
                />
                <van-field
                v-model="IDdate"
                is-link
                name="有效日期"
                label="有效日期"
                placeholder="请选择有效日期"
                :border="false"
                />
                <van-field
                v-model="phone"
                name="手机号码"
                label="手机号码"
                placeholder="请输入手机号码"
                :border="false"
                :rules="[{ required: true, message: '请填写手机号码' }]"
                />
            </van-cell-group>
            <div class="sumbit" >
                <div class="sumbit-top"><van-checkbox v-model="checked" checked-color="#009f1e">我已阅读《用户服务协议》和《用户隐私协议》</van-checkbox></div>
                <van-button color="#009f1e" round block type="primary" native-type="submit">
                提交
                </van-button>
            </div>
        </van-form>
    </div>

  </div>
    
</template>

<script>
import { Toast } from 'vant'
export default {
    data(){
        return{
            userName:'',
            sex:'',
            ethnic:'',
            birthday:null,
            address:'',
            ID:'',
            IDdate:null,
            phone:'',
            checked:false
        }
    },
    mounted() {

    },
    methods:{
        back(){
            this.$router.push('/')
        },
        onSubmit(){
            Toast('升级中，敬请期待');
        },
        tip(){
            Toast('升级中，敬请期待');
        }
    }
}
</script>

<style scoped>
.certification{
    padding: 0 10px;
    background-color:#f8f8f8 ;
}
.tip-top-icon{
    margin-top: 5px;
    margin-right: 14px;
    font-size: 25px;
}
.tip-top{
    display: flex;
    background-color: #5a5a5a;
    color: white;
    text-align: left;
    padding: 15px 10px 15px 15px;
    margin:10px 0 20px 0;
    border-radius:10px ;
    font-weight: bold;
}
.certification-form{
    background-color: white;
    padding:10px 5px 100px;
    text-align: left;
}
.certification-form-title{
    font-weight: 800;
    padding: 15px 20px 20px;
}
.img-flex{
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}
.img{
    width: 45%;
}
.sumbit{
    position: fixed;
    bottom: 0;
    left: 0 ;
    text-align: center;
    width:calc(100% - 40px);
    padding: 20px;
    font-size: 14px;
    background-color: white;
}
.sumbit-top{
    margin-bottom:10px ;
}
.back{
    text-align: left;
    padding: 10px 0 0;
}
</style>