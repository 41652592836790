<template>
    <div class="content">
        <div class="home">
            <div class="city" @click="toggleVisibility">
                <van-icon size="26" name="location-o" />
                城市选择
            </div>
            <div class="info_card" @click="toggleVisibility">
                <van-cell-group inset>
                    <div class="box_font">自助查询</div>
                    <van-cell>
                        <template #title>
                            <div class="cell_list">
                                <div class="cell_left">
                                    <img class="img_sea" src="../assets/01.png" alt="" />
                                    <div class="cell_font">查询签收口令</div>
                                </div>

                                <div class="cell_right">></div>
                            </div>
                        </template>
                    </van-cell>

                    <van-cell>
                        <template #title>
                            <div class="cell_list_phone">
                                <div class="cell_left">
                                    <img class="img_sea" src="../assets/02.png" alt="" />
                                    <div class="cell_font">收件座机号查询签收口令</div>
                                </div>
                                <div class="cell_right">></div>
                            </div>
                        </template>
                    </van-cell>
                </van-cell-group>
            </div>

            <div class="info-list" @click="toggleVisibility">
                <van-cell-group inset>
                    <div class="box_font_info">交警预约上门办</div>

                    <van-cell>
                        <template #title>
                            <div class="info-list_img">
                                <div class="cell_left">
                                    <img class="img_sea_info" src="../assets/03.png" alt="" />
                                    <div class="cell_font_info">摩托车上门 查验登记</div>
                                </div>
                                <div class="cell_right">
                                    <img class="img_sea_info" src="../assets/07.png" alt="" />
                                    <div class="cell_font_info">电动车上门 查验登记</div>
                                </div>
                            </div>
                        </template>
                    </van-cell>
                </van-cell-group>
            </div>
            <van-dialog />
            <!-- <div v-show="isVisible" class="toast">为保障您的物件安全，请先进行实名认证</div> -->
        </div>

        <navCom :active="active"></navCom>
    </div>
</template>

<script>
import navCom from '../components/nav.vue';
import { Dialog } from 'vant';
export default {
    data() {
        return {
            // isVisible: true,
            active: 0,
        };
    },

    name: 'HomeView',
    components: {
        navCom,
    },
    methods: {
        toggleVisibility() {
            // this.isVisible = !this.isVisible;
            Dialog.confirm({
                title: '温馨提示',
                message: '为保障您的物件安全，请先进行实名认证',
            })
                .then(() => {
                    // on confirm
                    this.$router.push('./certification');
                })
                .catch(() => {
                    // on cancel
                });
        },
    },
};
</script>
<style>
.content {
    background: #f8f8f8 100%;
}
.home {
    background: url(../assets/08.jpg) no-repeat;
    background-size: contain;
    height: 100vh;
    position: relative;
}

.box_font {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 600;
    text-align: left;
    margin-left: 20px;
}
.info_card {
    position: absolute;
    top: 100px;
    background-size: contain;
    width: 100%;
    margin-top: 154px;
}

.img_sea {
    height: 60px;
}
.cell_list {
    width: 100%;
    background-color: #c3eae6;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
}
.cell_left {
    display: flex;
}
.cell_right {
    display: flex;
    align-items: center;
}
.cell_font {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    margin-left: 10px;
}
.cell_list_phone {
    width: 100%;
    background-color: #ffe051;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
}
.info-list {
    position: absolute;
    top: 100px;
    background-size: contain;
    width: 100%;
    margin-top: 405px;
}
.box_font_info {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    margin-left: 20px;
}
.info-list_img {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}
.cell_font_info {
    width: 60px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 600;
    align-items: center;
    display: flex;
}
.img_sea_info {
    height: 40px;
}
.city {
    font-size: 18px;
    color: white;
    position: absolute;
    top: 15px;
    left: 20px;
    display: flex;
    align-items: center;
}
.toast {
    position: absolute;
    top: 100px;
    background: white;
}
</style>
