import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import My from '../views/my.vue'
import Certification from '../views/certification.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/My',
    name: 'my',
    component: My
  },
  {
    path: '/Certification',
    name: 'Certification',
    component: Certification
  }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
