<template>
    <div class="my">
        <div class="my-box">
        <div class="top-box" @click="to">
                <img src="../assets/06.png" width="70px" alt="" srcset="">
                <div class="info-right">
                    <div class="info-right-name">实名认证 <van-icon name="arrow" /></div>
                    <div class="info-right-tip">认证后才可操作</div>
                </div>
        </div>
        <div class="info-card">
            <div class="info-card-top">常用功能</div>
            <div class="info-card-cell">
                <van-cell-group :border="false">
                    <van-cell title="开具发票" icon="orders-o" :border="false" is-link @click="tip"/>
                    <van-cell title="地址簿" icon="notes-o" :border="false" is-link  @click="tip"/>
                    <van-cell title="联系我们" icon="service-o" :border="false" is-link @click="tip" />
                </van-cell-group>
            </div>
            
        </div>
        <navCom :active=active></navCom>
        </div>
    </div>
    
</template>

<script>
import navCom from '../components/nav.vue';
import { Toast } from 'vant'
export default {
    components: {
        navCom,
    },
    data(){
        return{
            active:1
        }
    },
    methods:{
        to(){
            this.$router.push('/certification')
        },
        tip(){
            Toast('敬请期待');
        }
    }   
}
</script>

<style  scoped>
    .my{
        background: #f8f8f8;height: 100vh
    }
    .my-box{
        width: calc(100% - 40px) ;
        background: url(../assets/09.png) no-repeat;
        background-size: contain;
        height: 100vh;
        padding: 20px;
    }
    .top-box{
        display: flex;
        align-items: center;
        padding: 100px 20px 0;
        height: 75px;
    }
    .info-right{
        text-align: left;
        margin-left: 20px;
        display: flex;
        flex-flow: column;
        justify-content: space-around;
        height: 100%;
    }
    .info-right-name{
        font-size:25px;
    }
    .info-right-tip{

    }
    .info-card{
        text-align: left;
        background-color: white;
        margin-top: 25px;
        border-radius:10px ;
        padding:15px 0px 20px;
    }
    .info-card-top{
        font-weight: bold;
        padding: 0 15px;
        margin-bottom: 10px;
    }
    .info-card-cell :deep(.van-cell__left-icon){
        font-size: 25px;
        color: #000;
        margin-right: 10px;
    }
    .info-card-cell  :deep(.van-cell__right-icon){
        font-size: 22px;
        color: #000;
    }
</style>