<template>
  <div style="width: 100%;" >
    <van-tabbar v-model="active1" active-color="#009f1e"  @change="onChange">
      <van-tabbar-item icon="wap-home">便民服务</van-tabbar-item>
      <van-tabbar-item icon="user">我的</van-tabbar-item>
    </van-tabbar>

  </div>
</template>

<script>
export default {
  props: {
    active: Number
  },
  name: 'nav-com',
  data () {
    return {
      active1:this.active
    }
  },
  methods:{
    onChange(index){
      if(index==this.active) return
      if(index==0){
        this.$router.push('/')
      }else{
        this.$router.push('/my')
      }
    }
  }
}
</script>

<style  scoped>
:deep(.van-tabbar-item__icon){
  font-size: 1.5rem !important;
}
</style>
